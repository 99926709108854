// components/Logo.js
import React from 'react';
import LogoImage from './logoLingvo.jpg';
import './Logo.css'; // Создайте файл Logo.css для стилей

const Logo = () => {
  return (
    <div className="logo-container">
      <img src={LogoImage} alt="Logo" className="logo-image" />
    </div>
  );
};

export default Logo;