import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSentences, addUserExpression } from '../redux/textsSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Button, Form, Alert } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { transformLang } from './Utils';

const SentenceList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sentences, loading, error, user } = useSelector(state => ({
    sentences: state.texts.sentences,
    loading: state.texts.loading,
    error: state.texts.error,
    user: state.texts.user, 
  }));
  const [showTranslations, setShowTranslations] = useState({});
  const { articleId } = useParams();
  const [selectedText, setSelectedText] = useState('');
  const [message, setMessage] = useState(null);

  const location = useLocation();
  const articleData = location.state;

  const articleText = articleData.text;
  const articleTitle = articleData.title;
  const articleTheme = articleData.theme;
  const articleTextLang = articleData.text_language;
  const articleTransLang = articleData.translation_language;

  const [isPlaying, setIsPlaying] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    dispatch(fetchSentences(articleId));
  }, [dispatch, articleId]);

  const handlePlay = () => {
    if (!user || !user.text_language) {
      console.error("User data is not available or text_language is missing.");
      return;
    }
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'speak', lang: transformLang(user.text_language), text: articleText, comp: 'SentenceList', voice: user.text_voice }));
    } else {
      const newUtterance = new SpeechSynthesisUtterance(articleText);
      newUtterance.lang = transformLang(user.text_language);
      setUtterance(newUtterance);
      speechSynthesis.speak(newUtterance);
    }
    setIsPlaying(true);
  };

  const handleStop = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'stop', comp: 'SentenceList', voice: user.text_voice }));
    } else {
      speechSynthesis.cancel();
    }
    setIsPlaying(false);
  };

  const handlePlayAudio = (text, lang) => {
    if (!user || !user.text_language) {
      console.error("User data is not available or text_language is missing.");
      return;
    }

    const lang_tr = transformLang(user.text_language);
    
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'speak', text, lang_tr, comp: 'SentenceList', voice: user.text_voice }));
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.lang = lang_tr;
      window.speechSynthesis.speak(msg);
    }
  };

  const toggleTranslation = (sentenceId) => {
    setShowTranslations({
      ...showTranslations,
      [sentenceId]: !showTranslations[sentenceId],
    });
  };

  const handleShowDictionary = (sentId, sentText) => {
    const sentenceData = {
      id: sentId,
      text: sentText,
    };

    navigate(`/dictionary/${sentId}`, { state: sentenceData });
  };

  const handleTextSelection = () => {
    const selected = window.getSelection()?.toString() || '';
    setSelectedText(selected);
  };

  const handleAddToDictionary = async () => {
    if (!selectedText) {
      setMessage({ text: 'Please select a word or phrase first.', variant: 'danger' });
      return;
    }

    try {
      const response = await dispatch(addUserExpression({ text: selectedText, theme: articleTheme }));
      if (response.payload.success) {
        setMessage({ text: 'Expression added to dictionary.', variant: 'success' });
        setSelectedText('');
      } else {
        setMessage({ text: 'Error adding expression to dictionary.', variant: 'danger' });
      }
    } catch (error) {
      setMessage({ text: 'Error adding expression to dictionary.', variant: 'danger' });
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const filteredSentences = sentences.filter(sentence => sentence.article.id === parseInt(articleId));

  const sentencesByTitle = filteredSentences.reduce((acc, sentence) => {
    if (!acc[sentence.article.name]) {
      acc[sentence.article.name] = [];
    }
    acc[sentence.article.name].push(sentence);
    return acc;
  }, {});

  return (
    <div className="container mt-1">
      <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
        <i className="bi bi-arrow-left"></i>
      </button>

      {message && (
        <Alert variant={message.variant} onClose={() => setMessage(null)} dismissible>
          {message.text}
        </Alert>
      )}
      <h5 className="mt-3 .font-weight-bold">{articleTitle}</h5>
      <div className="mb-1 .font-weight-bold">
        <Form.Group controlId="articleText">
          <Form.Label></Form.Label>
          <Form.Control
            as="textarea"
            rows={15}
            value={articleText}
            readOnly
            onMouseUp={handleTextSelection}
          />
        </Form.Group>
        <Button variant="primary" onClick={handleAddToDictionary} className="mt-3">
          <FaPlus />
        </Button>
        <button className="mt-3 btn btn-outline-secondary me-2" onClick={handlePlay} disabled={isPlaying}>
          <i className="bi bi-play-fill"></i>
        </button>
        <button className="mt-3 btn btn-outline-secondary me-2" onClick={handleStop} disabled={!isPlaying}>
          <i className="bi bi-stop-fill"></i>
        </button>
      </div>
      {Object.keys(sentencesByTitle).map(title => (
        <div key={title}>
          <h6 className="text-divider"><span>Sentence List</span></h6>
          <ul className="list-group">
            {sentencesByTitle[title].map(sentence => (
              <li key={sentence.id} className="list-group-item">
                <p><strong>{sentence.text}</strong></p>
                {showTranslations[sentence.id] && (
                  <p className="mt-2"><small>{sentence.translation}</small></p>
                )}
                <div className="mt-2 d-flex justify-content-between">
                  <button onClick={() => handlePlayAudio(sentence.text, articleTextLang)} className="btn btn-outline-secondary btn-sm me-2">
                    <i className="bi bi-play-fill"></i>
                  </button>
                  <button onClick={() => toggleTranslation(sentence.id)} className="btn btn-outline-secondary btn-sm me-2">
                    <i className="bi bi-eye"></i>
                  </button>
                  <button onClick={() => handleShowDictionary(sentence.id, sentence.text)} className="btn btn-outline-secondary btn-sm me-2">
                    <i className="bi bi-book"></i>
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default SentenceList;