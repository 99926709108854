import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { requestPasswordReset } from '../redux/textsSlice';
import { Form, Button, Alert } from 'react-bootstrap';

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(requestPasswordReset({ email })).unwrap();
      alert('Password reset email sent. Please check your inbox.');
      navigate('/login');
    } catch (err) {
      setError('Password reset request failed. Please try again.');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Request Password Reset</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Request Reset
        </Button>
      </Form>
    </div>
  );
};

export default PasswordResetRequest;