import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../redux/textsSlice';
import { Form, Button, Alert } from 'react-bootstrap';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [textLanguage, setTextLanguage] = useState('de');
  const [translationLanguage, setTranslationLanguage] = useState('ru');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null); // Добавляем состояние для сообщения об успехе
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Проверка на заполненность всех полей
    if (!username || !email || !password || !confirmPassword || !textLanguage || !translationLanguage) {
      setError('Please fill in all fields');
      return;
    }

    // Проверка на совпадение паролей
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await dispatch(registerUser({ username, email, password, textLanguage, translationLanguage })).unwrap();
      setSuccessMessage('Registration successful! A confirmation email has been sent to your email address.'); // Устанавливаем сообщение об успехе
      navigate('/login');
    } catch (err) {
      setError('Registration failed. Please try again.');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Register</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>} {/* Отображаем сообщение об успехе */}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formUsername">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
          />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </Form.Group>

        <Form.Group controlId="formConfirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
          />
        </Form.Group>

        <Form.Group controlId="formTextLanguage">
          <Form.Label>Text Language</Form.Label>
          <Form.Control
            as="select"
            value={textLanguage}
            onChange={(e) => setTextLanguage(e.target.value)}
          >
            <option value="de">German</option>
            <option value="en">English</option>
            <option value="ru">Russian</option>
            <option value="fr">French</option>
            <option value="es">Spanish</option>
            <option value="ar">Arabic</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formTranslationLanguage">
          <Form.Label>Translation Language</Form.Label>
          <Form.Control
            as="select"
            value={translationLanguage}
            onChange={(e) => setTranslationLanguage(e.target.value)}
          >
            <option value="de">German</option>
            <option value="en">English</option>
            <option value="ru">Russian</option>
            <option value="fr">French</option>
            <option value="es">Spanish</option>
            <option value="ar">Arabic</option>
          </Form.Control>
        </Form.Group>

        <div className="mt-3">
          <small>After registration, a confirmation email will be sent to your email address.</small>
        </div>

        <Button variant="primary" type="submit" className="mt-3">
          Register
        </Button>
      </Form>
    </div>
  );
};

export default Register;