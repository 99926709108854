import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import api from '../axios';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import './Flashcard.css';
import { FaVolumeUp, FaBook, FaComment } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { transformLang } from './Utils';


const Dictionary = () => {
  const { sentenceId } = useParams();
  const [words, setWords] = useState([]);
  const [selectedWord, setSelectedWord] = useState(null);
  const [newWord, setNewWord] = useState('');
  const [newTranslation, setNewTranslation] = useState('');
  const [newAudio, setNewAudio] = useState(null);
  const [currentListeningWordIndex, setCurrentListeningWordIndex] = useState(0);
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const [currentFlashcardIndex, setCurrentFlashcardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showListeningModal, setShowListeningModal] = useState(false);
  const [showFlashcardModal, setShowFlashcardModal] = useState(false);
  const [showSentenceModal, setShowSentenceModal] = useState(false);
  const [sentenceWords, setSentenceWords] = useState([]);
  const [userSentence, setUserSentence] = useState([]);
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();
  const user = useSelector(state => state.texts.user); // Получаем данные пользователя из Redux
  console.log(user)

  const location = useLocation();
  const sentenceData = location.state;

  const sentenceText = sentenceData.text;

  useEffect(() => {
    api.get(`/api/sentences/${sentenceId}/`)
      .then(response => setWords(response.data.words))
      .catch(error => console.error('Error fetching words:', error));
  }, [sentenceId]);

  const handleWordClick = (word) => {
    setSelectedWord(word);
    setShowEditModal(true);
  };

  const handleWordUpdate = (wordId, updatedData) => {
    api.put(`/api/words/${wordId}/`, updatedData)
      .then(response => {
        setWords(words.map(word => word.id === wordId ? response.data : word));
        setSelectedWord(null);
        setShowEditModal(false);
      })
      .catch(error => console.error('Error updating word:', error));
  };

  const handleWordDelete = (wordId) => {
    api.delete(`/api/words/${wordId}/`)
      .then(() => {
        setWords(words.filter(word => word.id !== wordId));
        setSelectedWord(null);
        setShowEditModal(false);
      })
      .catch(error => console.error('Error deleting word:', error));
  };

  const handleCreateWord = () => {
    const newWordData = {
      text: newWord,
      translation: newTranslation,
      audio: newAudio,
      sentence: sentenceId,
    };

    api.post(`/api/words/`, newWordData)
      .then(response => {
        setWords([...words, response.data]);
        setNewWord('');
        setNewTranslation('');
        setNewAudio(null);
        setShowCreateModal(false);
      })
      .catch(error => console.error('Error creating word:', error));
  };

  const handleListeningModeToggle = () => {
    setShowListeningModal(!showListeningModal);
    setCurrentListeningWordIndex(0);
    setSelectedTranslation(null);
  };

  const handleTranslationSelect = (translation) => {
    setSelectedTranslation(translation);
    if (translation === words[currentListeningWordIndex].translation) {
      setMessage({ text: 'Correct!', variant: 'success' });
      if (currentListeningWordIndex < words.length - 1) {
        setCurrentListeningWordIndex(currentListeningWordIndex + 1);
        setSelectedTranslation(null);
      } else {
        setMessage({ text: 'You have completed the listening exercise!', variant: 'success' });
        setShowListeningModal(false);
      }
    } else {
      setMessage({ text: 'Incorrect!', variant: 'danger' });
    }
  };

  const getRandomTranslations = (word) => {
    const translations = words.filter(w => w.id !== word.id).map(w => w.translation);
    const randomTranslations = shuffleArray(translations).slice(0, 3);
    return shuffleArray([...randomTranslations, word.translation]);
  };

  const handleFlashcardModeToggle = () => {
    setShowFlashcardModal(!showFlashcardModal);
    setCurrentFlashcardIndex(0);
    setIsFlipped(false);
  };

  const handleNextFlashcard = () => {
    if (currentFlashcardIndex < words.length - 1) {
      setCurrentFlashcardIndex(currentFlashcardIndex + 1);
      setIsFlipped(false);
    } else {
      setMessage({ text: 'You have completed the flashcard exercise!', variant: 'success' });
      setShowFlashcardModal(false);
    }
  };

  const handleFlipFlashcard = () => {
    setIsFlipped(!isFlipped);
  };

  const handleSentenceModeToggle = () => {
    setShowSentenceModal(!showSentenceModal);
    const sentenceWordsArray = sentenceText.split(' ');
    setSentenceWords(shuffleArray(sentenceWordsArray));
    setUserSentence([]);
  };

  const handleWordSelect = (word) => {
    setUserSentence([...userSentence, word]);
  };

  const handleCheckSentence = () => {
    const userSentenceText = userSentence.join(' ');
    const correctSentenceText = sentenceText;

    if (userSentenceText === correctSentenceText) {
      setMessage({ text: 'Correct!', variant: 'success' });
    } else {
      setMessage({ text: `Incorrect! The correct sentence is: ${correctSentenceText}`, variant: 'danger' });
    }

    setShowSentenceModal(false);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handlePlayAudio = (text, lang=user.text_language, voice) => { 
    let lang_tr = transformLang(lang);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'speak', text: text, lang_tr, comp: ' ', voice: voice }));
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.lang = lang_tr;
      window.speechSynthesis.speak(msg);
    }
  };

  return (
    <div className="container mt-1">
      <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
        <i className="bi bi-arrow-left"></i>
      </button>
      {message && (
        <Alert variant={message.variant} onClose={() => setMessage(null)} dismissible>
          {message.text}
        </Alert>
      )}
      <h5 className="mt-3">{sentenceText}</h5>
      <div className="d-flex align-items-center mt-3">
        <button onClick={() => handlePlayAudio(sentenceText, user.text_language, user.text_voice)} className="btn btn-outline-primary mb-3">
          <i className="bi bi-volume-up"></i> Play Sentence
        </button>
      </div>

      <h6 className="text-divider">
        <span>Quizes</span>
      </h6>

      <div className="d-flex justify-content-center mt-4">
        <button onClick={handleListeningModeToggle} className="btn btn-outline-primary me-2">
          <FaVolumeUp />
        </button>
        <button onClick={handleFlashcardModeToggle} className="btn btn-outline-primary me-2">
          <FaBook />
        </button>
        <button onClick={handleSentenceModeToggle} className="btn btn-outline-primary me-2">
          <FaComment />
        </button>
      </div>

      <h6 className="text-divider">
        <span>Dictionary</span>
      </h6>

      <button onClick={() => setShowCreateModal(true)} className="btn btn-outline-success mt-1 ms-2">
        Add New Word
      </button>
      <ul className="list-group mt-3">
        {words.map(word => (
          <li key={word.id} className="list-group-item d-flex justify-content-between align-items-center">
            {word.text} - {word.translation}
            <div>
              <button onClick={() => handleWordClick(word)} className="btn btn-outline-secondary btn-sm m-2">
                <i className="bi bi-pencil"></i>
              </button>
              <button onClick={() => handleWordDelete(word.id)} className="btn btn-outline-danger btn-sm m-2">
                <i className="bi bi-trash"></i>
              </button>
            </div>
          </li>
        ))}
      </ul>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Word</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedWord && (
            <Form>
              <Form.Group controlId="formWordText">
                <Form.Label>Word</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedWord.text}
                  onChange={(e) => setSelectedWord({ ...selectedWord, text: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formWordTranslation">
                <Form.Label>Translation</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedWord.translation || ''}
                  onChange={(e) => setSelectedWord({ ...selectedWord, translation: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formWordAudio">
                <Form.Label>Audio</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setSelectedWord({ ...selectedWord, audio: e.target.files[0] })}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="outline-primary" onClick={() => handleWordUpdate(selectedWord.id, selectedWord)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Word</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewWordText">
              <Form.Label>Word</Form.Label>
              <Form.Control
                type="text"
                value={newWord}
                onChange={(e) => setNewWord(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formNewWordTranslation">
              <Form.Label>Translation</Form.Label>
              <Form.Control
                type="text"
                value={newTranslation}
                onChange={(e) => setNewTranslation(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formNewWordAudio">
              <Form.Label>Audio</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setNewAudio(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowCreateModal(false)}>
            Close
          </Button>
          <Button variant="outline-primary" onClick={handleCreateWord}>
            Create Word
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showListeningModal} onHide={() => setShowListeningModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Listening Mode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {words.length > 0 && (
            <div>
              <div className="text-center">
                <button onClick={() => handlePlayAudio(words[currentListeningWordIndex].text, user.text_language, user.text_voice)} className="btn btn-outline-primary mb-3">
                  <i className="bi bi-volume-up"></i> Play
                </button>
              </div>
              <div className="row">
                {getRandomTranslations(words[currentListeningWordIndex]).map((translation, index) => (
                  <div
                    key={index}
                    className={`col-md-3 mb-3 text-center ${selectedTranslation === translation ? 'bg-primary' : 'bg-light'}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleTranslationSelect(translation)}
                  >
                    {translation}
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowListeningModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFlashcardModal} onHide={() => setShowFlashcardModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Flashcard Mode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {words.length > 0 && (
            <div>
              <div className="flashcard-container">
                <div className={`flashcard ${isFlipped ? 'flipped' : ''}`} onClick={handleFlipFlashcard}>
                  <div className="flashcard-front">
                    <div>{words[currentFlashcardIndex].text}</div>
                  </div>
                  <div className="flashcard-back">
                    <div>{words[currentFlashcardIndex].translation}</div>
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <Button variant="outline-primary" onClick={() => handlePlayAudio(isFlipped ? words[currentFlashcardIndex].translation : words[currentFlashcardIndex].text, isFlipped ? user.translation_language : user.text_language, isFlipped ? user.translation_voice : user.text_voice)}>
                    <i className="bi bi-volume-up"></i> Play Audio
                  </Button>
                  <Button variant="outline-primary" onClick={handleNextFlashcard}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowFlashcardModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSentenceModal} onHide={() => setShowSentenceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Sentence Mode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sentenceWords.length > 0 && (
            <div>
              <div className="row">
                {sentenceWords.map((word, index) => (
                  <div
                    key={index}
                    className="col-md-3 mb-3 text-center bg-light"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleWordSelect(word)}
                  >
                    {word}
                  </div>
                ))}
              </div>
              <div className="mt-3">
                <h4>Your Sentence:</h4>
                <p>{userSentence.join(' ')}</p>
                <button onClick={handleCheckSentence} className="btn btn-outline-primary">
                  Check Sentence
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowSentenceModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dictionary;