
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  analyzeText,
  createTheme,
  deleteTheme,
  setArticleText,
  fetchThemes,
} from "../redux/textsSlice";
import { Modal, Button, Form, Spinner, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu,
} from "react-contexify";
import api from "../axios";
import "react-contexify/dist/ReactContexify.css";
import "./TextDivider.css";

const TextAnalyzer = () => {
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [theme, setTheme] = useState("");
  const [newThemeName, setNewThemeName] = useState("");
  const [showTextModal, setShowTextModal] = useState(false);
  const [showThemeModal, setShowThemeModal] = useState(false);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [articleUrl, setArticleUrl] = useState("");
  const [error, setError] = useState("");
  const [showLoadArticleModal, setShowLoadArticleModal] = useState(false);
  const [youtubeVideoId, setYoutubeVideoId] = useState("");
  const [showLoadYoutubeTranscriptModal, setShowLoadYoutubeTranscriptModal] =
    useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [showLoadPdfModal, setShowLoadPdfModal] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [textModalError, setTextModalError] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [showLoadImageModal, setShowLoadImageModal] = useState(false);
  const [ocrLoading, setOcrLoading] = useState(false);
  const [ocrLanguage, setOcrLanguage] = useState("de"); // По умолчанию английский
  const [articleLanguage, setArticleLanguage] = useState("de-DE"); // По умолчанию немецкий
  const [translationLanguage, setTranslationLanguage] = useState("ru-RU"); // По умолчанию английский

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { themes } = useSelector((state) => state.texts);

  useEffect(() => {
    dispatch(fetchThemes());
    api
      .get("/api/articles/")
      .then((response) => setArticles(response.data.reverse()))
      .catch((error) => console.error("Error fetching articles:", error));
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!text || !title || !theme || !articleLanguage || !translationLanguage) {
      setTextModalError("Please fill in all fields.");
      return;
    }
    setLoading(true);
    dispatch(analyzeText({ text, title, theme_id: theme, language: articleLanguage, translation_language: translationLanguage }))
      .then(() => {
        setShowTextModal(false);
        api
          .get("/api/articles/")
          .then((response) => setArticles(response.data.reverse()))
          .catch((error) => console.error("Error fetching articles:", error));
        dispatch(setArticleText(text));
      })
      .catch((error) => {
        console.error("Error analyzing text:", error);
      })
      .finally(() => {
        setLoading(false);
        setTextModalError("");
      });
  };

  const handleShowText = (artId, artTitle, artText, artTheme, artTextLang, artTransLang) => {
    const articleData = {
      id: artId,
      title: artTitle,
      text: artText,
      theme: artTheme,
      text_language: artTextLang,
      translation_language: artTransLang
    };

    navigate(`/articles/${artId}`, { state: articleData });
  };

  const handleCreateTheme = () => {
    dispatch(createTheme({ name: newThemeName })).then((response) => {
      if (response.payload.success) {
        dispatch(fetchThemes());
        setNewThemeName("");
      }
    });
  };

  const handleDeleteTheme = (themeId) => {
    dispatch(deleteTheme(themeId)).then(() => {
      dispatch(fetchThemes());
    });
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
    api
      .get("/api/articles/", {
        params: { theme_id: e.target.value },
      })
      .then((response) => setArticles(response.data.reverse()))
      .catch((error) => console.error("Error fetching articles:", error));
  };

  const handleLoadArticle = async () => {
    setLoading(true);
    setError("");

    try {
      const encodedUrl = btoa(articleUrl);
      const response = await api.post("/api/parse-article/", {
        url: encodedUrl,
      });
      const articleText = response.data.text;
      setText(articleText);
      setShowTextModal(true);
      setShowLoadArticleModal(false);
    } catch (err) {
      setError("Failed to load article. Please check the URL and try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleLoadYoutubeTranscript = async () => {
    setLoading(true);
    setError("");

    try {
      const encodedVideoId = btoa(youtubeVideoId);
      const response = await api.post("/api/get-youtube-transcript/", {
        video_id: encodedVideoId,
      });
      const transcriptText = response.data.text;
      setText(transcriptText);
      setShowTextModal(true);
      setShowLoadYoutubeTranscriptModal(false);
    } catch (err) {
      setError(
        "Failed to load transcript. Please check the video ID and try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setPdfFile(file);
      setPdfLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await api.post("/api/upload-pdf/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const pdfText = response.data.text;
        setText(pdfText);
        setShowLoadPdfModal(false);
        setShowTextModal(true);
      } catch (err) {
        setError("Failed to load PDF. Please try again.");
      } finally {
        setPdfLoading(false);
      }
    }
  };

  const handleDeleteArticle = async (articleId) => {
    try {
      await api.delete(`/api/articles/${articleId}/`);
      setArticles(articles.filter((article) => article.id !== articleId));
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setOcrLoading(true);
      const formData = new FormData();
      formData.append("image", file);
      formData.append("language", ocrLanguage); // Добавляем выбранный язык

      try {
        const response = await api.post("/api/upload-image/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const imageText = response.data.text;
        setText(imageText);
        setShowLoadImageModal(false);
        setShowTextModal(true);
      } catch (err) {
        setError("Failed to extract text from image. Please try again.");
      } finally {
        setOcrLoading(false);
      }
    }
  };

  const { show } = useContextMenu({
    id: "articleMenu",
  });

  const displayMenu = (e, articleId) => {
    e.preventDefault();
    show({
      event: e,
      props: {
        articleId,
      },
    });
  };

  return (
    <div className="container mt-3">
      <div class="d-flex justify-content-center">
        <ButtonGroup className="mb-3">
          <Button
            variant="outline-secondary"
            onClick={() => setShowThemeModal(true)}
            className="me-2 mb-3"
          >
            <i class="bi bi-globe2"></i> Themes
          </Button>

          <Button
            variant="outline-secondary"
            onClick={() => navigate("/user-expressions")}
            className="me-2 mb-3"
          >
            <i className="bi bi-book"></i> Expressions
          </Button>
        </ButtonGroup>
      </div>

      <div class="d-flex justify-content-center">
        <ButtonGroup className="mb-3">
          <Button
            variant="outline-primary"
            onClick={() => setShowTextModal(true)}
            className="me-2"
          >
            <i className="bi bi-plus-circle"></i> Text
          </Button>
          <Button
            variant="outline-success"
            onClick={() => setShowLoadArticleModal(true)}
            className="me-2"
          >
            <i className="bi bi-download"></i> Web
          </Button>

          {/* <Button
            variant="outline-info"
            onClick={() => setShowLoadYoutubeTranscriptModal(true)}
            className="me-2"
          >
            <i className="bi bi-youtube"></i> YouTube
          </Button> */}
          
          <Button variant="outline-warning" onClick={() => setShowLoadPdfModal(true)}>
            <i className="bi bi-file-earmark-pdf"></i> PDF
          </Button>
          <Button variant="outline-secondary" onClick={() => setShowLoadImageModal(true)} className="ms-2">
            <i className="bi bi-image"></i> Image
          </Button>
        </ButtonGroup>
      </div>
      <div className="form-group">
        <label>Theme</label>
        <select
          className="form-control form-select mb-3"
          value={theme}
          onChange={handleThemeChange}
        >
          <option value="">All Themes</option>
          {themes.map((theme) => (
            <option key={theme.id} value={theme.id}>
              {theme.name}
            </option>
          ))}
        </select>
      </div>

      <Modal show={showTextModal} onHide={() => setShowTextModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Добавить текст</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title here..."
              />
            </Form.Group>
            <Form.Group controlId="formTheme">
              <Form.Label>Theme</Form.Label>
              <Form.Control
                as="select"
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
              >
                <option value="">Select theme</option>
                {themes.map((theme) => (
                  <option key={theme.id} value={theme.id}>
                    {theme.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formLanguage">
              <Form.Label>Language</Form.Label>
              <Form.Control
                as="select"
                value={articleLanguage}
                onChange={(e) => setArticleLanguage(e.target.value)}
              >
            <option value="de-DE">Deutsch (Deutschland)</option>
            <option value="ru-RU">Русский (Россия)</option>
            <option value="en-US">English (US)</option>
            <option value="es-ES">Spanish (Spain)</option>
            <option value="fr-FR">French (France)</option>
                {/* Добавьте другие языки по необходимости */}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formTranslationLanguage">
              <Form.Label>Translation Language</Form.Label>
              <Form.Control
                as="select"
                value={translationLanguage}
                onChange={(e) => setTranslationLanguage(e.target.value)}
              >
            <option value="de-DE">Deutsch (Deutschland)</option>
            <option value="ru-RU">Русский (Россия)</option>
            <option value="en-US">English (US)</option>
            <option value="es-ES">Spanish (Spain)</option>
            <option value="fr-FR">French (France)</option>
                {/* Добавьте другие языки по необходимости */}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formText">
              <Form.Label>Enter text here</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter text here..."
              />
            </Form.Group>
          </Form>
          {textModalError && (
            <div className="alert alert-danger mt-3">{textModalError}</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowTextModal(false)}>
            Close
          </Button>
          <Button
            variant="outline-primary"
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Analyze"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showThemeModal} onHide={() => setShowThemeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Управление темами</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>New Theme</label>
            <input
              type="text"
              className="form-control"
              value={newThemeName}
              onChange={(e) => setNewThemeName(e.target.value)}
              placeholder="Enter new theme name..."
            />
          </div>
          <button onClick={handleCreateTheme} className="btn btn-outline-primary mt-2">
            Create Theme
          </button>
          <ul className="list-group mt-3">
            {themes.map((theme) => (
              <li
                key={theme.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                {theme.name}
                <button
                  onClick={() => handleDeleteTheme(theme.id)}
                  className="btn btn-outline-danger"
                >
                  <i class="bi bi-trash3"></i>
                </button>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowThemeModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showLoadArticleModal}
        onHide={() => setShowLoadArticleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Load Article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formArticleUrl">
              <Form.Label>Enter Article URL</Form.Label>
              <Form.Control
                type="text"
                value={articleUrl}
                onChange={(e) => setArticleUrl(e.target.value)}
                placeholder="https://www.tagesschau.de/..."
              />
            </Form.Group>
          </Form>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowLoadArticleModal(false)}
          >
            Close
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleLoadArticle}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Load Article"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showLoadYoutubeTranscriptModal}
        onHide={() => setShowLoadYoutubeTranscriptModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Load from YouTube</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formYoutubeVideoId">
              <Form.Label>Enter YouTube Video ID</Form.Label>
              <Form.Control
                type="text"
                value={youtubeVideoId}
                onChange={(e) => setYoutubeVideoId(e.target.value)}
                placeholder="e.g., dQw4w9WgXcQ"
              />
            </Form.Group>
          </Form>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowLoadYoutubeTranscriptModal(false)}
          >
            Close
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleLoadYoutubeTranscript}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Load Transcript"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLoadPdfModal} onHide={() => setShowLoadPdfModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Load PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPdfFile">
              <Form.Label>Upload PDF File</Form.Label>
              <Form.Control
                type="file"
                accept="application/pdf"
                onChange={handleFileUpload}
              />
            </Form.Group>
          </Form>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowLoadPdfModal(false)}
          >
            Close
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleFileUpload}
            disabled={pdfLoading}
          >
            {pdfLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Upload PDF"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLoadImageModal} onHide={() => setShowLoadImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Load Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formImageFile">
              <Form.Label>Upload Image File</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </Form.Group>
            <Form.Group controlId="formOcrLanguage">
              <Form.Label>Select OCR Language</Form.Label>
              <Form.Control
                as="select"
                value={ocrLanguage}
                onChange={(e) => setOcrLanguage(e.target.value)}
              >
                <option value="en">English</option>
                <option value="ru">Russian</option>
                <option value="de">German</option>
                {/* Добавьте другие языки по необходимости */}
              </Form.Control>
            </Form.Group>
          </Form>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowLoadImageModal(false)}
          >
            Close
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleImageUpload}
            disabled={ocrLoading}
          >
            {ocrLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Extract Text"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <h6 class="text-divider">
        <span>Articles</span>
      </h6>
      <ol className="list-group list-group-numbered">
        {articles.map((article) => (
          <li
            key={article.id}
            className="list-group-item d-flex justify-content-between align-items-start"
            onClick={() =>
              handleShowText(
                article.id,
                article.name,
                article.text,
                article.theme,
                article.text_language,
                article.translation_language,
              )
            }
            onContextMenu={(e) => displayMenu(e, article.id)}
            style={{ cursor: "pointer" }}
          >
            <div class="ms-2 me-auto">
              <div class="fw-bold">{article.name}</div>
            </div>
          </li>
        ))}
      </ol>

      <Menu id="articleMenu">
        <Item
          onClick={({ event, props }) => handleDeleteArticle(props.articleId)}
        >
          Delete Article
        </Item>
      </Menu>
    </div>
  );
};

export default TextAnalyzer;