import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserExpressions, addUserExpression, deleteUserExpression, fetchThemes } from '../redux/textsSlice';
import api from '../axios';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import './Flashcard.css'; // Импортируем CSS для флешкарт
import { FaBook, FaMicrophone, FaEye } from 'react-icons/fa'; // Импортируем иконки
import { transformLang } from './Utils';


const UserExpressionsList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userExpressions, loading, error, themes } = useSelector((state) => state.texts);
  const [selectedExpression, setSelectedExpression] = useState(null);
  const [newExpression, setNewExpression] = useState('');
  const [newTranslation, setNewTranslation] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showFlashcardsModal, setShowFlashcardsModal] = useState(false);
  const [showAudioRecognitionModal, setShowAudioRecognitionModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [currentFlashcardIndex, setCurrentFlashcardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [audioRecognitionInput, setAudioRecognitionInput] = useState('');
  const [showTranslations, setShowTranslations] = useState({});

  const user = useSelector(state => state.texts.user);

  useEffect(() => {
    dispatch(fetchUserExpressions(selectedTheme));
    dispatch(fetchThemes());
  }, [dispatch, selectedTheme]);

  const handleThemeChange = (event) => {
    const themeId = event.target.value;
    setSelectedTheme(themeId);
  };

  const handleExpressionClick = (expression) => {
    setSelectedExpression(expression);
    setShowEditModal(true);
  };

  const handleExpressionUpdate = (expressionId, updatedData) => {
    api.put(`/api/user-expressions/${expressionId}/`, updatedData)
      .then(response => {
        dispatch(fetchUserExpressions(selectedTheme));
        setSelectedExpression(null);
        setShowEditModal(false);
      })
      .catch(error => console.error('Error updating expression:', error));
  };

  const handleExpressionDelete = (expressionId) => {
    api.delete(`/api/user-expressions/${expressionId}/`)
      .then(() => {
        dispatch(fetchUserExpressions(selectedTheme));
        setSelectedExpression(null);
        setShowEditModal(false);
      })
      .catch(error => console.error('Error deleting expression:', error));
  };

  const handleCreateExpression = () => {
    const newExpressionData = {
      text: newExpression,
      translation: newTranslation,
      theme: selectedTheme,
    };

    dispatch(addUserExpression(newExpressionData))
      .then(() => {
        dispatch(fetchUserExpressions(selectedTheme));
        setNewExpression('');
        setNewTranslation('');
        setShowCreateModal(false);
      })
      .catch(error => console.error('Error creating expression:', error));
  };

  const handlePlayAudio = (text, lang, voice) => {
    const lang_tr = transformLang(lang);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'speak', text: text, lang: lang_tr, comp: ' ', voice: voice }));
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.lang = lang_tr;
      window.speechSynthesis.speak(msg);
    }
  };

  const handleNextFlashcard = () => {
    if (currentFlashcardIndex < userExpressions.length - 1) {
      setCurrentFlashcardIndex(currentFlashcardIndex + 1);
      setIsFlipped(false);
    } else {
      setShowFlashcardsModal(false);
    }
  };

  const handleCheckAudioRecognition = () => {
    const currentExpression = userExpressions[currentFlashcardIndex];
    if (audioRecognitionInput.toLowerCase() === currentExpression.text.toLowerCase()) {
      setMessage({ variant: 'success', text: 'Correct!' });
    } else {
      setMessage({ variant: 'danger', text: 'Incorrect!' });
    }
    setAudioRecognitionInput('');
  };

  const toggleTranslation = (expressionId) => {
    setShowTranslations({
      ...showTranslations,
      [expressionId]: !showTranslations[expressionId],
    });
  };

  return (
    <div className="container mt-1">
      <button onClick={() => navigate(-1)} className="btn btn-outline-secondary me-2">
        <i className="bi bi-arrow-left"></i>
      </button>
      {message && (
        <Alert variant={message.variant} onClose={() => setMessage(null)} dismissible>
          {message.text}
        </Alert>
      )}
      <h2 className="mt-3">User Expressions</h2>
      <Form.Group controlId="themeSelect" className="mb-3">
        <Form.Label>Select Theme</Form.Label>
        <Form.Control as="select" value={selectedTheme || ''} onChange={handleThemeChange}>
          <option value="">All Themes</option>
          {themes.map(theme => (
            <option key={theme.id} value={theme.id}>{theme.name}</option>
          ))}
        </Form.Control>
      </Form.Group>
      <div className="d-flex justify-content-center mb-3">
        <button onClick={() => setShowFlashcardsModal(true)} className="btn btn-outline-primary me-2">
          <FaBook />
        </button>
        <button onClick={() => setShowAudioRecognitionModal(true)} className="btn btn-outline-primary me-2">
          <FaMicrophone />
        </button>
      </div>
      <button onClick={() => setShowCreateModal(true)} className="btn btn-outline-success mb-3">
        Create New Expression
      </button>
      <ul className="list-group mt-3">
        {userExpressions.map(expression => (
          <li key={expression.id} className="list-group-item">
            <p>{expression.text}</p>
            {showTranslations[expression.id] && (
              <p className="mt-2"><small>{expression.translation}</small></p>
            )}
            <div className="mt-2 d-flex justify-content-between">
              <button onClick={() => handlePlayAudio(expression.text, user.text_language, user.text_voice)} className="btn btn-outline-primary btn-sm me-2">
                <i className="bi bi-volume-up"></i>
              </button>
              <button onClick={() => handleExpressionClick(expression)} className="btn btn-outline-secondary btn-sm me-2">
                <i className="bi bi-pencil"></i>
              </button>
              <button onClick={() => handleExpressionDelete(expression.id)} className="btn btn-outline-danger btn-sm me-2">
                <i className="bi bi-trash"></i>
              </button>
              <button onClick={() => toggleTranslation(expression.id)} className="btn btn-outline-secondary btn-sm">
                <FaEye />
              </button>
            </div>
          </li>
        ))}
      </ul>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Expression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedExpression && (
            <Form>
              <Form.Group controlId="formExpressionText">
                <Form.Label>Expression</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedExpression.text}
                  onChange={(e) => setSelectedExpression({ ...selectedExpression, text: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formExpressionTranslation">
                <Form.Label>Translation</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedExpression.translation || ''}
                  onChange={(e) => setSelectedExpression({ ...selectedExpression, translation: e.target.value })}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="outline-primary" onClick={() => handleExpressionUpdate(selectedExpression.id, selectedExpression)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Expression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewExpressionText">
              <Form.Label>Expression</Form.Label>
              <Form.Control
                type="text"
                value={newExpression}
                onChange={(e) => setNewExpression(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formNewExpressionTranslation">
              <Form.Label>Translation</Form.Label>
              <Form.Control
                type="text"
                value={newTranslation}
                onChange={(e) => setNewTranslation(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formNewExpressionTheme">
              <Form.Label>Theme</Form.Label>
              <Form.Control as="select" value={selectedTheme || ''} onChange={(e) => setSelectedTheme(e.target.value)}>
                <option value="">Select Theme</option>
                {themes.map(theme => (
                  <option key={theme.id} value={theme.id}>{theme.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowCreateModal(false)}>
            Close
          </Button>
          <Button variant="outline-primary" onClick={handleCreateExpression}>
            Create Expression
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFlashcardsModal} onHide={() => setShowFlashcardsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Flashcards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userExpressions.length > 0 && (
            <div className="flashcard-container">
              <div className={`flashcard ${isFlipped ? 'flipped' : ''}`} onClick={() => setIsFlipped(!isFlipped)}>
                <div className="flashcard-front">
                  <div>{userExpressions[currentFlashcardIndex].text}</div>
                </div>
                <div className="flashcard-back">
                  <div>{userExpressions[currentFlashcardIndex].translation}</div>
                </div>
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <Button variant="outline-primary" onClick={() => handlePlayAudio(isFlipped ? userExpressions[currentFlashcardIndex].translation : userExpressions[currentFlashcardIndex].text, isFlipped ? user.translation_language : user.text_language, isFlipped ? user.translation_voice : user.text_voice)}>
                  <i className="bi bi-volume-up"></i> Play Audio
                </Button>
                <Button variant="primary" onClick={handleNextFlashcard}>
                  Next
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowFlashcardsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAudioRecognitionModal} onHide={() => setShowAudioRecognitionModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Audio Recognition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userExpressions.length > 0 && (
            <div>
              <Button variant="outline-primary" onClick={() => handlePlayAudio(userExpressions[currentFlashcardIndex].text, user.text_language, user.text_voice)} className="mb-3">
                Play Audio
              </Button>
              <Form.Group controlId="formAudioRecognitionInput">
                <Form.Label>Enter the expression you heard:</Form.Label>
                <Form.Control
                  type="text"
                  value={audioRecognitionInput}
                  onChange={(e) => setAudioRecognitionInput(e.target.value)}
                />
              </Form.Group>
              <Button variant="outline-primary" onClick={handleCheckAudioRecognition} className="mt-3">
                Check
              </Button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowAudioRecognitionModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserExpressionsList;