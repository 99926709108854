import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../redux/textsSlice';
import { Navbar, Nav, Button } from 'react-bootstrap';
import './Navbar.css';

const CustomNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'exitApp'}));
      } else {
        navigate('/login');
      }
    } catch (err) {
      alert('Logout failed. Please try again.');
    }
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  return (
    // <Navbar bg="light" expand="lg">
    //   <Navbar.Brand href="/">
    //     <strong>E-Lingvo</strong>
    //   </Navbar.Brand>
    //   <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //   <Navbar.Collapse id="basic-navbar-nav">
    //     <Nav className="mr-auto">
    //       <Nav.Link onClick={handleProfileClick}>Profile</Nav.Link>
    //       {/* Другие ссылки могут быть добавлены здесь */}
    //     </Nav>
    //     <Button variant="outline-danger" onClick={handleLogout} className="ml-auto">
    //       Logout
    //     </Button>
    //   </Navbar.Collapse>
    // </Navbar>

<nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <a className="navbar-brand" href="/">
      <span>E</span>-Lingvo
    </a>
    <div className="d-flex align-items-center ms-auto">
      <button className="btn btn-outline-secondary me-2" onClick={handleProfileClick}>
        <i className="bi bi-gear"></i>
      </button>
      <button className="btn btn-outline-danger" onClick={handleLogout}>
        Exit
      </button>
    </div>
  </div>
</nav>

  );

};

export default CustomNavbar;