import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await axios.get(`https://e-lingvo.duckdns.org/verify-email/${token}/`);
        alert('Email verified successfully!');
        navigate('/login');
      } catch (error) {
        alert('Email verification failed. Please try again.');
        navigate('/register');
      }
    };

    verifyEmail();
  }, [token, navigate]);

  return (
    <div>
      <h2>Verifying your email...</h2>
    </div>
  );
};

export default VerifyEmail;