import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import api from '../axios';


export const fetchThemes = createAsyncThunk('texts/fetchThemes', async () => {
  const response = await api.get(`/api/themes/`);
  return response.data;
});

export const createTheme = createAsyncThunk('texts/createTheme', async (themeData) => {
  const response = await api.post(`/api/themes/create/`, themeData);
  return response.data;
});

export const deleteTheme = createAsyncThunk('texts/deleteTheme', async (themeId) => {
  await api.delete(`/api/themes/delete/${themeId}/`);
  return themeId;
});

export const fetchUserExpressions = createAsyncThunk(
  'texts/fetchUserExpressions',
  async (themeId = null, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/user-expressions/', {
        params: { theme_id: themeId }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUserExpression = createAsyncThunk(
  'texts/addExpressionToDictionary',
  async (expressionData, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/user-expressions/', expressionData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserExpression = createAsyncThunk('texts/deleteUserExpression', async (expressionId) => {
  await api.delete(`/api/user-expressions/${expressionId}/`);
  return expressionId;
});

export const analyzeText = createAsyncThunk('texts/analyzeText', async (textData) => {
  const response = await api.post(`/api/texts/`, textData);
  console.log(response.data)
  return response.data;
});

export const setArticleText = createAsyncThunk('texts/setArticleText', async (text) => {
  return text;
});

export const fetchSentences = createAsyncThunk('texts/fetchSentences', async (articleId) => {
  const response = await api.get(`/api/articles/${articleId}/sentences/`);
  return response.data;
});

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post('/login/', credentials);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginWithGoogle = createAsyncThunk(
  'auth/loginWithGoogle',
  async (token, { rejectWithValue }) => {
    try {
      const response = await api.post('/login/google/', { token });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'texts/logoutUser',
  async (_, { rejectWithValue }) => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        throw new Error('Refresh token is missing');
      }

      const response = await api.post('/logout/', { refresh_token: refreshToken });
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  'texts/registerUser',
  async ({ username, email, password, textLanguage, translationLanguage }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/register/`, {
        username,  
        email,
        password,
        text_language: textLanguage,
        translation_language: translationLanguage,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const requestPasswordReset = createAsyncThunk(
  'texts/requestPasswordReset',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/password-reset-request/`, { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'texts/resetPassword',
  async ({ token, password }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/reset-password/${token}/`, { password });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const googleLogin = createAsyncThunk(
  'texts/googleLogin',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.post(`/google-login/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserProfile = createAsyncThunk(
  'texts/fetchUserProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/profile/');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveUserSettings = createAsyncThunk(
  'texts/saveUserSettings',
  async (settings, { rejectWithValue }) => {
    try {
      const response = await api.patch('/profile/', settings, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const textsSlice = createSlice({
  name: 'texts',
  initialState: {
    themes: [],
    userExpressions: [],
    sentences: [],
    loading: false,
    error: null,
    isAuthenticated: false,
    user: null, 
    textLanguage: 'de-DE',
    translationLanguage: 'ru-RU',
    textVoice: null,
    translationVoice: null,
  },
  reducers: {
    setTextLanguage: (state, action) => {
      state.textLanguage = action.payload;
    },
    setTranslationLanguage: (state, action) => {
      state.translationLanguage = action.payload;
    },
    setTextVoice: (state, action) => {
      state.textVoice = action.payload;
    },
    setTranslationVoice: (state, action) => {
      state.translationVoice = action.payload;
    },
    logoutUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchThemes.fulfilled, (state, action) => {
        state.themes = action.payload;
      })
      .addCase(createTheme.fulfilled, (state, action) => {
        state.themes.push(action.payload);
      })
      .addCase(deleteTheme.fulfilled, (state, action) => {
        state.themes = state.themes.filter(theme => theme.id !== action.payload);
      })
      .addCase(fetchUserExpressions.fulfilled, (state, action) => {
        state.userExpressions = action.payload;
      })
      .addCase(addUserExpression.fulfilled, (state, action) => {
        state.userExpressions.push(action.payload);
      })
      .addCase(deleteUserExpression.fulfilled, (state, action) => {
        state.userExpressions = state.userExpressions.filter(expression => expression.id !== action.payload);
      })
      .addCase(analyzeText.fulfilled, (state, action) => {
        // Handle analyzeText success
      })
      .addCase(setArticleText.fulfilled, (state, action) => {
        // Handle setArticleText success
      })
      .addCase(fetchSentences.fulfilled, (state, action) => {
        state.sentences = action.payload;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.isAuthenticated = true;
        localStorage.setItem('access_token', action.payload.access_token);
        localStorage.setItem('refresh_token', action.payload.refresh_token);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(loginWithGoogle.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.isAuthenticated = true;
        localStorage.setItem('access_token', action.payload.access_token);
        localStorage.setItem('refresh_token', action.payload.refresh_token);
      })
      .addCase(loginWithGoogle.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null;
        state.isAuthenticated = false;
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.textLanguage = action.payload.text_language || 'de-DE';
        state.translationLanguage = action.payload.translation_language || 'ru-RU';
        state.textVoice = action.payload.text_voice || null;
        state.translationVoice = action.payload.translation_voice || null;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(saveUserSettings.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
      })
      .addCase(saveUserSettings.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addMatcher(
        (action) => action.type.endsWith('/pending'),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled'),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

export const {
  setTextLanguage,
  setTranslationLanguage,
  setTextVoice,
  setTranslationVoice,
} = textsSlice.actions;

export default textsSlice.reducer;

